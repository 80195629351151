import ListItemText from "@mui/material/ListItemText";
import { FossLibrary } from "../entities/FossLibrary";
import { useTranslation } from "react-i18next";

type LibraryVersionProps = {
  library: FossLibrary;
};

export const hasLibraryVersion = (library: FossLibrary) =>
  !!library.installedVersion && library.installedVersion !== "n/a";

export function LibraryVersion({ library }: LibraryVersionProps) {
  const { t } = useTranslation();

  const secondary = hasLibraryVersion(library) ? library.installedVersion : "";

  return (
    <ListItemText
      primary={t("thirdpartylibraries.version")}
      secondary={secondary}
    />
  );
}
