import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { FossLibrary } from "../entities/FossLibrary";
import { LicenseItem } from "./LicenseItem";

interface Props {
  libraries: FossLibrary[];
  appName: string;
}

export function AppLibraryList({ libraries, appName }: Props) {
  return (
    <>
      <Typography variant="h4">{appName}</Typography>
      <List>
        {libraries.map((library, index) => {
          return <LicenseItem key={index} library={library} />;
        })}
      </List>
    </>
  );
}
