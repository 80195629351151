import { useTranslation } from "react-i18next";
import ListItemText from "@mui/material/ListItemText";
import { FossLibrary } from "../entities/FossLibrary";
import { Link } from "./Link";

export const hasRepositoryLink = (library: FossLibrary) =>
  !!library.link && library.link !== "n/a";

type RepositoryLinkProps = {
  library: FossLibrary;
};

export function RepositoryLink({ library }: RepositoryLinkProps) {
  const { t } = useTranslation();

  /**
   * remove the "git+" prefix from the repository link to make usable as href
   * and also remove the ".git" at the end of the link
   */
  const parsedRepositoryLink = library.link
    .replace(/^(git\+https:|git\+ssh:|git:|ssh:)\/\//, "https://")
    .replace(/\.(git)$/, "");

  return (
    <ListItemText
      primary={t("thirdpartylibraries.repository")}
      secondary={
        <Link href={parsedRepositoryLink}>{parsedRepositoryLink}</Link>
      }
    />
  );
}
// taskforcesh/bullmq-pro
