import Typography from "@mui/material/Typography";
import { FossLibrary } from "../entities/FossLibrary";

type LibraryNameProps = {
  library: FossLibrary;
};

export function LibraryName({ library }: LibraryNameProps) {
  return (
    <Typography variant="h5" sx={{ marginBottom: 3 }}>
      {library.name.startsWith("@")
        ? library.name.substring(1, library.name.length)
        : library.name}
    </Typography>
  );
}
