import { ListItem, Paper } from "@mui/material";
import { FossLibrary } from "../entities/FossLibrary";
import { LibraryVersion } from "./LibaryVersion";
import { LibraryName } from "./LibraryName";
import { LicenseType, hasLicenseType } from "./LicenseType";
import { RepositoryLink, hasRepositoryLink } from "./RepositoryLink";

interface Props {
  library: FossLibrary;
}

export function LicenseItem({ library }: Props) {
  return (
    <ListItem
      component={Paper}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        margin: 3,
      }}
    >
      <LibraryName library={library} />
      <LibraryVersion library={library} />
      {hasLicenseType(library) && <LicenseType library={library} />}
      {hasRepositoryLink(library) && <RepositoryLink library={library} />}
    </ListItem>
  );
}
