import MuiLink, { LinkProps } from "@mui/material/Link";

export function Link(props: LinkProps) {
  return (
    <MuiLink
      target="_blank"
      rel="noopener noreferrer"
      color="inherit"
      {...props}
    />
  );
}
