import { FossLibrary } from "../entities/FossLibrary";

const recognizedLicenseTypes = [
  "Apache-2.0",
  "MIT",
  "Commercial",
  "BSD-2-Clause",
  "ISC",
];

export const MuiCommercialLicense = "Material-UI Commercial License";

export const specialLibraryLookup: Record<string, string> = {
  "@mui/x-data-grid-pro": MuiCommercialLicense,
  "@mui/x-license-pro": MuiCommercialLicense,
};

/**
 * if the library name contains "dormakaba", then no license is needed, and this function throws an error
 * otherwise for all special cases we need to have a lookup table
 */
export const lookUpLicenseType = (library: FossLibrary): string | undefined => {
  if (library.name.includes("dormakaba")) {
    throw new Error(
      "Dormakaba libraries are not shown in the report, so this should never happen.",
    );
  }

  if (recognizedLicenseTypes.includes(library.licenseType)) {
    return library.licenseType;
  }
  // it's ok if result is undefined
  return (specialLibraryLookup as any)[library.name];
};
