import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import adminPortalReport from "../generated-reports/resivo-admin-portal.json";
import apiReport from "../generated-reports/resivo-api.json";
import { AppLibraryList } from "../components/AppLibraryList";
import { FossLibrary } from "../entities/FossLibrary";
import "./i18n";

const sortLibraries = (libraries: FossLibrary[]) =>
  [...libraries].sort((a, b) => (a.name < b.name ? -1 : 1));
const adminPortalLibraries = sortLibraries(adminPortalReport);
const apiLibraries = sortLibraries(apiReport);

export function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const language = urlParams.get("lang");

  const { i18n } = useTranslation();

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [i18n, language]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={2}>
      <AppLibraryList
        appName="Resivo Admin Portal"
        libraries={adminPortalLibraries}
      />
      <AppLibraryList appName="Resivo Api" libraries={apiLibraries} />
    </Box>
  );
}
