import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "../locales/en.json";
import translationDE from "../locales/de.json";
import translationFR from "../locales/fr.json";
import translationIT from "../locales/it.json";

export const supportedLanguages = ["en", "de", "fr", "it"];

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  fr: {
    translation: translationFR,
  },
  it: {
    translation: translationIT,
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  supportedLngs: supportedLanguages,
  lng: window?.navigator?.language,
  resources,
  interpolation: {
    // not needed for react as it escapes by default
    escapeValue: false,
  },
});
